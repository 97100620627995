.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
    background-position: center;
}

.signup-logo {
    width: 40%;
}

.signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    width: 100%;
    height: auto;
}

.signup-label {
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.signup-form .form-control {
    position: relative;
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(201, 201, 201);
}

.already {
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-shadow: black 0 0 1px;
    text-align: left;
}

.create-button {
    background-color: #C97852;
    font-weight: bold;
    font-size: 18px;
    color: white;
    padding: 10px 20px;
    border: none;
    display: inline-block;
}

.create-button:hover {
    background-color: #8d4321;
    color: white;
}