.pond-list-header h1{
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    padding-top: 20px;
}

.pond-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
}
.pond-card{
    background-color: #D9D9D9 !important;
    width: 100%; 
    max-width: 300px; 
    height: 450px; 
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-top: 70px;
}

.pond-card img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.pond-card h5 {
    font-size: 20px;
    font-weight: bold;
}

