@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  background-color: #ebe9e9;
  font-family: "Open Sans", sans-serif;
}
.admin-header {
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.admin-header .logo {
  width: 80px;
}
.header-link {
  text-decoration: none;
  color: inherit;
}
.logo-text {
  color: black;
  font-weight: bold;
  font-size: 27px;
}
.admin-header .row {
  margin: 0px !important;
}

.admin-header button.rounded-circle {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  background: #f0f5ff !important;
}
.account .account-img .rounded-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.account .account-img .rounded-circle img {
  object-fit: cover;
  width: 100%;
}
.account .accountInfo {
  padding-left: 10px;
  padding-top: 16px;
}
.account .accountInfo h4 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.account .accountInfo p {
  margin-top: 0;
  font-size: 12px;
  color: #000;
  opacity: 0.7;
}
/* ----------------------Sidebar -------------------- */
.main .sidebarWrapper {
  width: 19%;
  flex: 0 0 19%;
  transition: all 0.3 ease-in-out;
}
.main .sidebarWrapper.toggle {
  width: 0%;
  flex: 0 0 0%;
}

.main .sidebarWrapper .sidebar {
  width: 19%;
  height: 100vh;
  position: fixed;
  top: 80px;
  left: 0px;
  background: #fff;
}
.sidebarWrapper .sidebar {
  left: 0;
  transition: all 0.26s ease-in-out;
}
.main .sidebarWrapper.toggle .sidebar {
  left: -100%;
}

.main .sidebarWrapper .sidebar ul {
  margin-bottom: 0;
}
.main .sidebarWrapper .sidebar ul li {
  list-style: none;
}
.main .sidebarWrapper .sidebar ul li button {
  color: #000;
  text-align: left;
  justify-content: left;
}
.main .sidebarWrapper .sidebar ul li button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.main .sidebarWrapper .sidebar ul li button .icon svg {
  color: #000;
  opacity: 0.8;
  font-size: 22px;
}
.sidebar ul li {
  font-size: 50px;
}

/*---------------- Dashboard -----------*/

.main .content {
  width: 80%;
  flex: 0 0 80%;
  transition: all 0.3s ease-in-out;
}
.main .content.toggle {
  width: 80%;
  flex: 0 0 100%;
}

.right-content {
  padding: 90px 30px;
}
.dashboardBoxWrapper {
  gap: 29px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 8px;
  background-color: #fff;
  padding: 20px;
  padding-left: 30px;
  border-radius: 10px;
}
.dashboardBoxWrapper .dashboardBox {
  width: 30%;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dashboardBoxWrapper .dashboardBox .col1 h4 {
  font-size: 18px;
  font-weight: 600;
}
.dashboardBoxWrapper .dashboardBox .col1 span {
  font-size: 26px !important;
  font-weight: bold;
  padding-left: 18px;
}
.dashboardBoxWrapper .dashboardBox .icon svg {
  font-size: 45px;
  opacity: 0.1;
  color: #000;
}
/*-------------------  Members   ---------------------*/
.members-content {
  margin-top: 50px;
  margin-left: 8px;
  background-color: #fff;
  border-radius: 10px;
}
.actions button {
  min-width: 25px;
  width: 35px !important;
  height: 35px;
}
.actions button .icon svg {
  height: 18px;
  width: 18px;
  color: #000;
}

button .icon svg {
  height: 18px;
  width: 18px;
  color: #000;
}

.member-content-header {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/*------------------- Revenue Dashboard ----------- */
.dashboardChart {
  margin-top: 70px;
  background-color: #fff;
  border-radius: 8px;
  padding: 70px;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
/* .dashboardChart .chartContainer .chartTitle {
  text-align: center;
} */
.subRevenueChart {
  display: flex;
  gap: 60px;
  margin-top: 50px;
}
.chartContainer .chartOptions {
  display: flex;
  justify-content: end;
}
/*---------- Admin Create Account -------------------- */

/*----------------Order Management------------------------------*/
/* .custom-viewOrderDetailDialog {
  height: 600px;
  width: 1000px;
  max-width: 100%;
} */
