.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
    background-position: center;
}

.login-logo {
    width: 40%;
}

.nav-tabs-login {
    display: flex;
    text-align: center;
    color: #333;
    max-width: 600px;
    width: 100%;
    height: auto;
    border-bottom: none;
}

.nav-tabs-login .nav-link.active {
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #C97852;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
}

.nav-tabs-login .nav-link {
    color: rgb(0, 0, 0);
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: none;
}

.nav-tabs-login .nav-link:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #C97852;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    width: 100%;
    height: auto;
}

.login-label {
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.login-form .form-control {
    position: relative;
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(192, 192, 192);
}

.forgot-password {
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-shadow: black 0 0 1px;
    text-align: left;
}

.login-button {
    background-color: #C97852;
    font-weight: bold;
    font-size: 18px;
    color: white;
    padding: 10px 20px;
    border: none;
    display: inline-block;
}

.login-button:hover {
    background-color: #8d4321;
    color: white;
}

.error-message {
    color: red;
    font-weight: bold;
}

.google-login {
    background-color: rgb(209, 208, 208);
    background-color: transparent rgb(0, 0, 0);
    border: 1px solid black;
    box-shadow: black 0 0 4px;
    padding: 10px 130px;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.google-login:hover {
    background-color: #c97852;
    color: white;
}