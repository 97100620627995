.food-calculator-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.food-calculator {
  margin-bottom: 20px;
  background-color:  white;
  padding: 24px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.food-calculator .form-label {
  font-weight: bold;
}

.feeding-info {
  max-width: 416px;
  height: fit-content;
  padding: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  margin-bottom: 20px;
}

.salt-input-group {
  display: flex;
  align-items: center;
}

.food-result {
  margin-top: 24px;
  text-align: center;
}

.temperature-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.temperature-buttons button {
  flex: 1;
  margin: 0 5px;
}