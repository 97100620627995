.pond-card{
  background-color: none;
}
.pond-detail {
    margin-left: 20px;
}

.pond-detail li{
    font-size: 36px;
    font-weight: 600;
    padding: 10px 0;
}
.card{
  &:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }
}