.navbar {
  width: 100%;
  height: 100px;
  background-color: #e47e39;
  margin-bottom: 50px;

  .container-fluid {
    background-color: white;
    border-bottom: 1px solid black;
    box-shadow: rgb(116, 115, 115) 0px 4px 4px;
  }
}
.user-header {
  margin-right: 175px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: white;
}

.dropdown-centered .dropdown-menu {
  left: 80%;
  transform: translateX(-80%);
}

.nav-title {
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  color: black;
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out;
  &.active {
    background-color: #e47e39;
    border: none;
    border-radius: 25px;
  }
}

.custom-dropdown {
  font-size: 25px;
  font-weight: bold;
  padding: 2px 20px 2px 20px;
  &:hover {
    background-color: #e47e39;
    border-radius: 25px;
  }
}
.dropdown-item {
  font-size: 20px;
}

.navbar-logo {
  height: 100px;
  padding-left: 150px;
  padding-right: 100px;
}

.nav-right {
  padding-right: 150px;
}

.search-icon {
  padding-top: 10px;
  width: 30px;
  height: 30px;
}
