.shop-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.shop-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: chocolate;
}

.shop-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-filter {
  margin-bottom: 20px;
}

.category-select {
  margin-left: 10px;
}

.productList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  list-style-type: none;
  padding: 0;
}

.productItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
}

.productLink {
  text-decoration: none;
  color: black;
}

.productImage {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.product-card img {
  border: 1px solid #d3d3d3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 270px;
  height: 270px;
  object-fit: cover;
}

.shop-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.shop-pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.shop-pagination span {
  margin: 0 10px;
}
