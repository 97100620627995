.main-content{
  background-color: #FAEEE6;
}
.homepage {
    padding: 20px;
}

/* landing section */
.landing{
    margin: 0 150px;
    margin-top: 100px;
}
  .hero-section {
    align-items: center;
    margin-bottom: 40px;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
  }

.text-section {
    padding-right: 200px;
}  

.text-section h1 {
    font-weight: bold;
    color: #9E4A11;
    padding-bottom: 10px;
}
.text-section h2 {
    font-weight: bold;
    color: #CA6420;
  }

.text-section p{
    font-size: 18px;
    color: #CA6420;
}
.text-section button{
    background-color: #703209;
    font-size: 25px;
    font-weight: bold;
    width: 182px;
    height: 80px;
    border: none;
    border-radius: 10px;
    &:hover{
        background-color: #CA6420;
    }
}
/* features section */

.features-section {
  padding: 50px 0;
  background-color: #FAEEE6;
  padding-bottom: 124px;
}
.feature{
  margin: 0 150px;
}
.features-section h1 {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 60px;
  padding-bottom: 100px;
  padding-top: 50px;
}
.features-section h2{
  color: black;
}
.card-footer a{
  flex-direction: row;
}
.card-img-top {
  object-fit: cover;
  height: 220px;
  padding: 10px 20px;
  border-radius: 25px;
}
.home-card {
  background-color: #F6DAC7 !important;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-container img{
  width: 125px;
  height: 125px;
  display: block;
  justify-content: start;
}
.card-title {
  font-weight: bold;
}

.card-text {
  font-size: 14px;
  display: flex;
  text-align: left;
  height: 100px;
}


.card-footer a{
  color: black;
  display: flex;
  font-weight: bold;
  font-size: 20px;
  &:hover{
    text-shadow: rgb(114, 114, 114) 0 4px 4px;
  }
}


